module.exports = {
  data: {
    results: [
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-15",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-16",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-17",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-18",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-19",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-20",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-21",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-2-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-9",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-10",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-11",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-12",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-13",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-14",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-15",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-16",
        deaths: 4,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-17",
        deaths: 5,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-18",
        deaths: 8,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-19",
        deaths: 9,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-20",
        deaths: 12,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-21",
        deaths: 19,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-22",
        deaths: 21,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-23",
        deaths: 25,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-24",
        deaths: 26,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-25",
        deaths: 30,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-26",
        deaths: 38,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-27",
        deaths: 54,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-28",
        deaths: 61,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-29",
        deaths: 64,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-30",
        deaths: 80,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-3-31",
        deaths: 101,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-4-1",
        deaths: 109,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-4-2",
        deaths: 139,
        __typename: "Result",
      },
      {
        country: { name: "Canada", __typename: "Country" },
        date: "2020-4-3",
        deaths: 179,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-15",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-16",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-17",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-18",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-19",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-20",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-21",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-22",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-23",
        deaths: 3,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-24",
        deaths: 7,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-25",
        deaths: 10,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-26",
        deaths: 12,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-27",
        deaths: 17,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-28",
        deaths: 21,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-2-29",
        deaths: 29,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-1",
        deaths: 34,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-2",
        deaths: 52,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-3",
        deaths: 79,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-4",
        deaths: 107,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-5",
        deaths: 148,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-6",
        deaths: 197,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-7",
        deaths: 233,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-8",
        deaths: 366,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-9",
        deaths: 463,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-10",
        deaths: 631,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-11",
        deaths: 827,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-12",
        deaths: 827,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-13",
        deaths: 1266,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-14",
        deaths: 1441,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-15",
        deaths: 1809,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-16",
        deaths: 2158,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-17",
        deaths: 2503,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-18",
        deaths: 2978,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-19",
        deaths: 3405,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-20",
        deaths: 4032,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-21",
        deaths: 4825,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-22",
        deaths: 5476,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-23",
        deaths: 6077,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-24",
        deaths: 6820,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-25",
        deaths: 7503,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-26",
        deaths: 8215,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-27",
        deaths: 9134,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-28",
        deaths: 10023,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-29",
        deaths: 10779,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-30",
        deaths: 11591,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-3-31",
        deaths: 12428,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-4-1",
        deaths: 13155,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-4-2",
        deaths: 13915,
        __typename: "Result",
      },
      {
        country: { name: "Italy", __typename: "Country" },
        date: "2020-4-3",
        deaths: 14681,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-15",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-16",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-17",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-18",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-19",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-20",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-21",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-22",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-23",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-24",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-25",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-26",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-27",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-28",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-2-29",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-1",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-2",
        deaths: 3,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-3",
        deaths: 4,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-4",
        deaths: 4,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-5",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-6",
        deaths: 9,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-7",
        deaths: 11,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-8",
        deaths: 19,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-9",
        deaths: 19,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-10",
        deaths: 33,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-11",
        deaths: 48,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-12",
        deaths: 48,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-13",
        deaths: 79,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-14",
        deaths: 91,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-15",
        deaths: 91,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-16",
        deaths: 149,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-17",
        deaths: 149,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-18",
        deaths: 149,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-19",
        deaths: 244,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-20",
        deaths: 451,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-21",
        deaths: 563,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-22",
        deaths: 676,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-23",
        deaths: 862,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-24",
        deaths: 1102,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-25",
        deaths: 1333,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-26",
        deaths: 1698,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-27",
        deaths: 1997,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-28",
        deaths: 2317,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-29",
        deaths: 2611,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-30",
        deaths: 3030,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-3-31",
        deaths: 3532,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-4-1",
        deaths: 4043,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-4-2",
        deaths: 5398,
        __typename: "Result",
      },
      {
        country: { name: "France", __typename: "Country" },
        date: "2020-4-3",
        deaths: 6520,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-15",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-16",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-17",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-18",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-19",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-20",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-21",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-2-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-9",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-10",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-11",
        deaths: 3,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-12",
        deaths: 3,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-13",
        deaths: 7,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-14",
        deaths: 9,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-15",
        deaths: 11,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-16",
        deaths: 17,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-17",
        deaths: 24,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-18",
        deaths: 28,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-19",
        deaths: 44,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-20",
        deaths: 67,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-21",
        deaths: 84,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-22",
        deaths: 94,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-23",
        deaths: 123,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-24",
        deaths: 157,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-25",
        deaths: 206,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-26",
        deaths: 267,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-27",
        deaths: 342,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-28",
        deaths: 433,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-29",
        deaths: 533,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-30",
        deaths: 645,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-3-31",
        deaths: 775,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-4-1",
        deaths: 920,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-4-2",
        deaths: 1107,
        __typename: "Result",
      },
      {
        country: { name: "Germany", __typename: "Country" },
        date: "2020-4-3",
        deaths: 1275,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-13",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-14",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-15",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-16",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-17",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-18",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-19",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-20",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-21",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-22",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-23",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-24",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-25",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-26",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-27",
        deaths: 4,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-28",
        deaths: 4,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-2-29",
        deaths: 5,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-1",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-2",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-3",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-4",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-5",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-6",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-7",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-8",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-9",
        deaths: 10,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-10",
        deaths: 10,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-11",
        deaths: 15,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-12",
        deaths: 16,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-13",
        deaths: 19,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-14",
        deaths: 22,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-15",
        deaths: 22,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-16",
        deaths: 27,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-17",
        deaths: 29,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-18",
        deaths: 29,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-19",
        deaths: 29,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-20",
        deaths: 33,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-21",
        deaths: 35,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-22",
        deaths: 41,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-23",
        deaths: 42,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-24",
        deaths: 43,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-25",
        deaths: 45,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-26",
        deaths: 47,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-27",
        deaths: 49,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-28",
        deaths: 52,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-29",
        deaths: 54,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-30",
        deaths: 54,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-3-31",
        deaths: 56,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-4-1",
        deaths: 57,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-4-2",
        deaths: 62,
        __typename: "Result",
      },
      {
        country: { name: "Japan", __typename: "Country" },
        date: "2020-4-3",
        deaths: 63,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-15",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-16",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-17",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-18",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-19",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-20",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-21",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-22",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-23",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-24",
        deaths: 8,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-25",
        deaths: 10,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-26",
        deaths: 12,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-27",
        deaths: 13,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-28",
        deaths: 13,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-2-29",
        deaths: 16,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-1",
        deaths: 17,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-2",
        deaths: 28,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-3",
        deaths: 28,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-4",
        deaths: 35,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-5",
        deaths: 35,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-6",
        deaths: 42,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-7",
        deaths: 44,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-8",
        deaths: 50,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-9",
        deaths: 53,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-10",
        deaths: 54,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-11",
        deaths: 60,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-12",
        deaths: 66,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-13",
        deaths: 66,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-14",
        deaths: 72,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-15",
        deaths: 75,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-16",
        deaths: 75,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-17",
        deaths: 81,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-18",
        deaths: 84,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-19",
        deaths: 91,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-20",
        deaths: 94,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-21",
        deaths: 102,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-22",
        deaths: 111,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-23",
        deaths: 111,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-24",
        deaths: 120,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-25",
        deaths: 126,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-26",
        deaths: 131,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-27",
        deaths: 139,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-28",
        deaths: 144,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-29",
        deaths: 152,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-30",
        deaths: 158,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-3-31",
        deaths: 162,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-4-1",
        deaths: 165,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-4-2",
        deaths: 169,
        __typename: "Result",
      },
      {
        country: { name: "Korea, South", __typename: "Country" },
        date: "2020-4-3",
        deaths: 174,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-15",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-16",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-17",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-18",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-19",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-20",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-21",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-2-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-3",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-4",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-5",
        deaths: 3,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-6",
        deaths: 5,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-7",
        deaths: 10,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-8",
        deaths: 17,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-9",
        deaths: 28,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-10",
        deaths: 35,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-11",
        deaths: 54,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-12",
        deaths: 55,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-13",
        deaths: 133,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-14",
        deaths: 195,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-15",
        deaths: 289,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-16",
        deaths: 342,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-17",
        deaths: 533,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-18",
        deaths: 623,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-19",
        deaths: 830,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-20",
        deaths: 1043,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-21",
        deaths: 1375,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-22",
        deaths: 1772,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-23",
        deaths: 2311,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-24",
        deaths: 2808,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-25",
        deaths: 3647,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-26",
        deaths: 4365,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-27",
        deaths: 5138,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-28",
        deaths: 5982,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-29",
        deaths: 6803,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-30",
        deaths: 7716,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-3-31",
        deaths: 8464,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-4-1",
        deaths: 9387,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-4-2",
        deaths: 10348,
        __typename: "Result",
      },
      {
        country: { name: "Spain", __typename: "Country" },
        date: "2020-4-3",
        deaths: 11198,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-15",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-16",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-17",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-18",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-19",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-20",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-21",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-2-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-11",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-12",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-13",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-14",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-15",
        deaths: 3,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-16",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-17",
        deaths: 7,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-18",
        deaths: 10,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-19",
        deaths: 11,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-20",
        deaths: 16,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-21",
        deaths: 20,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-22",
        deaths: 21,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-23",
        deaths: 25,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-24",
        deaths: 36,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-25",
        deaths: 62,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-26",
        deaths: 77,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-27",
        deaths: 105,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-28",
        deaths: 105,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-29",
        deaths: 110,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-30",
        deaths: 146,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-3-31",
        deaths: 180,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-4-1",
        deaths: 239,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-4-2",
        deaths: 308,
        __typename: "Result",
      },
      {
        country: { name: "Sweden", __typename: "Country" },
        date: "2020-4-3",
        deaths: 358,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-15",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-16",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-17",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-18",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-19",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-20",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-21",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-2-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-5",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-6",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-7",
        deaths: 2,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-8",
        deaths: 3,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-9",
        deaths: 4,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-10",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-11",
        deaths: 8,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-12",
        deaths: 8,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-13",
        deaths: 8,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-14",
        deaths: 21,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-15",
        deaths: 21,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-16",
        deaths: 56,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-17",
        deaths: 56,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-18",
        deaths: 72,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-19",
        deaths: 138,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-20",
        deaths: 178,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-21",
        deaths: 234,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-22",
        deaths: 282,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-23",
        deaths: 336,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-24",
        deaths: 423,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-25",
        deaths: 466,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-26",
        deaths: 580,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-27",
        deaths: 761,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-28",
        deaths: 1021,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-29",
        deaths: 1231,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-30",
        deaths: 1411,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-3-31",
        deaths: 1793,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-4-1",
        deaths: 2357,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-4-2",
        deaths: 2926,
        __typename: "Result",
      },
      {
        country: { name: "United Kingdom", __typename: "Country" },
        date: "2020-4-3",
        deaths: 3611,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-29",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-30",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-1-31",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-1",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-2",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-3",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-4",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-5",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-6",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-7",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-8",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-9",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-10",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-11",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-12",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-13",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-14",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-15",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-16",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-17",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-18",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-19",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-20",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-21",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-22",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-23",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-24",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-25",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-26",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-27",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-28",
        deaths: 0,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-2-29",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-1",
        deaths: 1,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-2",
        deaths: 6,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-3",
        deaths: 7,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-4",
        deaths: 11,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-5",
        deaths: 12,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-6",
        deaths: 14,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-7",
        deaths: 17,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-8",
        deaths: 21,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-9",
        deaths: 22,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-10",
        deaths: 28,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-11",
        deaths: 36,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-12",
        deaths: 40,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-13",
        deaths: 47,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-14",
        deaths: 54,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-15",
        deaths: 63,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-16",
        deaths: 85,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-17",
        deaths: 108,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-18",
        deaths: 118,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-19",
        deaths: 200,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-20",
        deaths: 244,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-21",
        deaths: 307,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-22",
        deaths: 417,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-23",
        deaths: 557,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-24",
        deaths: 706,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-25",
        deaths: 942,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-26",
        deaths: 1209,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-27",
        deaths: 1581,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-28",
        deaths: 2026,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-29",
        deaths: 2467,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-30",
        deaths: 2978,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-3-31",
        deaths: 3873,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-4-1",
        deaths: 4757,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-4-2",
        deaths: 5926,
        __typename: "Result",
      },
      {
        country: { name: "US", __typename: "Country" },
        date: "2020-4-3",
        deaths: 7087,
        __typename: "Result",
      },
    ],
  },
};
